@layer tailwind-base, primeng, tailwind-utilities;

@import 'primeng/resources/primeng.css';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeicons/primeicons.css';

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

:root {
  --font-family: 'Poppins', sans-serif;
  --primary-color: #2ea3f2;

  font-family: var(--font-family);
  background-color: #f8fafc;
  font-size: 16px;
}

/** Typography */
h4 {
  font-family: var(--font-family);
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

h5 {
  font-family: var(--font-family);
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

/** Button */
@layer primeng {
  .p-button {
    border: none;
    background: linear-gradient(241.11deg, #00ffd8 0%, #0029e2 100%);
  }

  .p-button.p-button-text {
    background: none;
    color: var(--primary-color);
  }
  .p-button.p-button-outlined {
    color: white;
  }
}

.p-button-label {
  font-weight: normal;
}

/** Input */
.p-inputtext {
  line-height: 24px;
  height: 48px;
}

label {
  font-size: 1rem;
  font-weight: 400;
  color: #1e293b;
}

.p-inputtext.ng-touched.ng-invalid {
  border-color: #e24c4c;
}

p-calendar.ng-touched.ng-invalid .p-inputtext {
  border-color: #e24c4c;
}

/** Dropdown */
p-dropdown.ng-touched.ng-invalid > .p-dropdown {
  border-color: #e24c4c;
}

.p-dropdown,
.p-multiselect {
  width: 100%;
}

admin-payment-response {
  p-card {
    box-shadow: 0px 10px 10px -5px #0000000a;
    box-shadow: 0px 20px 25px -5px #0000001a;

    max-width: 36rem !important;

    .p-card-body {
      max-width: 36rem !important;
      padding: 32px;
    }
  }
}

.tooltip-match {
  background-color: #e24c4c;
  width: auto;
}

ngx-stripe-card {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding: 2px;
  min-height: 48px;
}

.calendar-tab-view .p-tabview-nav-link {
  padding: 16px !important;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
  padding: 0.75rem !important;
}

.p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
  background: var(--primary-color);

  a {
    color: white;
  }
}
